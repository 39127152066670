<template>
  <div>
    <div class="contentbox" v-if="pc == 'pc'">
      <div class="content">
        <div class="focus">
          <div>关注我们</div>
          <div
            class="focusicon"
            v-for="(item, index) in icon"
            :key="index"
            @click="tolinkpc(item.link, item.describe)"
          >
            <img
              :src="item.status == 'normal' ? item.normal : item.light"
              alt=""
              @mouseover="hoverin(item)"
              @mouseout="hoverout(item)"
              class="focusicon"
            />
            <div
              class="iconhover"
              :style="'backgroundImage:url(' + item.iconhover + ')'"
              v-if="item.iconhover"
              v-show="item.hover"
            ></div>
          </div>
        </div>
        <div class="line"></div>
        <div class="infor">
          <div class="Joinus">
            <div>加入我们</div>
            <div @click="tojoinus" class="point">招聘官网</div>
          </div>
          <!-- <div class="legal">
            <div>法律信息</div>
            <div>服务协议</div>
            <div>知识产权</div>
          </div> -->
          <div class="contact">
            <div>联系我们</div>
            <div>电话：021-64360386</div>
            <div>邮件：contact@liangyihui.net</div>
            <div>地址：苏州市工业园区国际科技园四期A1103/1104</div>
            <div class="addressstyle">
              上海市徐汇区田林路140号锦和尚城T2栋9楼 邮编：200233
            </div>
          </div>
          <img
            src="//bosdev.liangyihui.net/temp/1617181398.png"
            alt=""
            class="logoimg"
          />
        </div>
        <div class="bottom">
          <div class="bottomStyle">
            <div class="suPrepared">
              <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002004080" style="display:inline-block;text-decoration:none;">
                <img src="https://bosdev.liangyihui.net/temp/1660200509.png" class="nationalEmblem"/>
                <p style="float:left; margin: 0px 0px 0px 5px; color:#fff;">
                  苏公网安备32059002004080号
                </p>
              </a>
            </div>
            <p style=" color:#fff;margin-left: 24px;">
                苏ICP备15039310号
                </p>
                <p style="color:#fff;margin-left: 24px;">
                  互联网药品信息服务资格证书 (苏)-非经营性-2024-0113
                </p>
          </div>
          <div>
            良医汇版权所有 Copyright © 2015-{{nowYear}} Liangyihui. All Rights Reserved. 
          </div>
        </div>
      </div>
    </div>
    <div v-if="pc == 'mobil'" class="mbcontentbox">
      <div class="mbcontent">
        <div>
          <div class="mbfocus">
            <div>关注我们</div>
            <div
              class="mbfocusicon"
              v-for="(item, index) in icon"
              :key="index"
              @click="tolink(item.link)"
            >
              <img
                :src="item.status == 'normal' ? item.normal : item.light"
                alt=""
                @mousedown="hoverclick(item)"
                class="mbfocusicon"
              />
              <div
                class="mbiconhover"
                :style="'backgroundImage:url(' + item.iconhover + ')'"
                v-if="item.iconhover"
                v-show="item.hover"
              ></div>
            </div>
          </div>
          <div class="mbline"></div>
          <div class="mbinfor">
            <div class="mbJoinus">
              <div class="mbJoinus-spread">
                <div @click="changejoin">加入我们</div>
                <img
                  :src="
                    joinusspread
                      ? '//bosdev.liangyihui.net/temp/1618885673.png'
                      : '//bosdev.liangyihui.net/temp/1618885678.png'
                  "
                  alt=""
                  @click="changejoin"
                />
              </div>
              <div
                v-show="joinusspread"
                class="mbJoinus-show"
                @click="tojoinus"
              >
                招聘官网
              </div>
            </div>
            <!-- <div class="mblegal">
              <div class="mblegal-spread">
                <div @click="changelegal">法律信息</div>
                <img
                  :src="
                    mblegalspread
                      ? '//bosdev.liangyihui.net/temp/1618885673.png'
                      : '//bosdev.liangyihui.net/temp/1618885678.png'
                  "
                  alt=""
                  @click="changelegal"
                />
              </div>
              <div class="mblegal-show" v-show="mblegalspread">服务协议</div>
              <div class="mblegal-show" v-show="mblegalspread">知识产权</div>
            </div> -->
            <div class="mbcontact mbcontact-nobuttom">
              <div class="mbcontact-spread">
                <div @click="changecontact">联系我们</div>
                <img
                  :src="
                    mbcontact
                      ? '//bosdev.liangyihui.net/temp/1618885673.png'
                      : '//bosdev.liangyihui.net/temp/1618885678.png'
                  "
                  alt=""
                  @click="changecontact"
                />
              </div>

              <div class="mbcontact-show" v-show="mbcontact">
                电话：021-64360386
              </div>
              <div class="mbcontact-show" v-show="mbcontact">
                邮件：contact@liangyihui.net
              </div>
              <div class="mbcontact-show" v-show="mbcontact">
                地址：苏州市工业园区国际科技园四期A1103/1104
              </div>
              <div
                class="mbcontact-show mbcontact-nobuttom addressstyle"
                v-show="mbcontact"
              >
              上海市徐汇区田林路140号锦和尚城T2栋9楼 邮编：200233
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mbbottom">
        <div>
          <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002004080" style="text-decoration:none; color: #9898a2">
            <img src="https://bosdev.liangyihui.net/temp/1660200509.png" class="mbnationalImg" alt="">
            苏公网安备32059002004080号
          </a>
        </div>
        <div>苏ICP备15039310号</div>
        <div>互联网药品信息服务资格证书 (苏)-非经营性-2024-0113</div>
        <div>
          良医汇版权所有 Copyright © 2015-{{nowYear}} Liangyihui. All Rights Reserved.
        </div>
        <div class="gotop" @click="gotopmethod">回到顶部</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mbcontact: false,
      joinusspread: false,
      mblegalspread: false,
      pc: null,
      nowYear:'',
      icon: [
        {
          normal: '//bosdev.liangyihui.net/temp/1617179268.png',
          light: '//bosdev.liangyihui.net/temp/1617256728.png',
          link:
            'https://weibo.com/oncologynewspts/profile?rightmod=1&wvr=6&mod=personinfo',
          status: 'normal',
          describe: 'weibo',
        },
        {
          normal: '//bosdev.liangyihui.net/temp/1617179320.png',
          light: '//bosdev.liangyihui.net/temp/1617256671.png',
          link:
            'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA5MTA0MjMwOQ==&scene=110#wechat_redirect',
          iconhover: '//bosdev.liangyihui.net/temp/1618816848.png',
          status: 'normal',
          describe: 'wechat',
          hover: false,
        },
        {
          normal: '//bosdev.liangyihui.net/temp/1617179385.png',
          light: '//bosdev.liangyihui.net/temp/1617256667.png',
          link: 'https://twitter.com/Oncologynewspro',
          status: 'normal',
          describe: 'twitter',
        },
        {
          normal: '//bosdev.liangyihui.net/temp/1617179401.png',
          light: '//bosdev.liangyihui.net/temp/1617256663.png',
          link:
            'https://www.toutiao.com/c/user/token/MS4wLjABAAAA0Pb-eeW0U7TYeSlHiJSpBDffHvtSU0zQOyqfC1m0lHA/',
          status: 'normal',
          describe: 'headline',
        },
      ],
    }
  },
  created() {
    this.pc = this.IsPC()
    this.nowYear = new Date().getFullYear()
  },
  methods: {
    tojoinus() {
      window.location.href =
        'https://www.zhipin.com/gongsir/1e38d899a58654fe1nd83d-6.html?ka=company-jobs'
    },
    gotopmethod() {
      window.scroll(0, 0)
    },
    changecontact() {
      if (this.mbcontact) {
        this.mbcontact = false
      } else {
        this.mbcontact = true
      }
    },
    changelegal() {
      if (this.mblegalspread) {
        this.mblegalspread = false
      } else {
        this.mblegalspread = true
      }
    },
    changejoin() {
      if (this.joinusspread) {
        this.joinusspread = false
      } else {
        this.joinusspread = true
      }
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent
      var Agents = new Array(
        'Android',
        'iPhone',
        'SymbianOS',
        'Windows Phone',
        'iPad',
        'iPod'
      )
      var flag = 'pc'
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = 'mobil'
          break
        }
      }
      return flag
    },
    hoverclick(item) {
      if (item.status == 'normal') {
        item.status = 'light'
      }
    },
    hoverin(item) {
      if (item.status == 'normal') {
        item.status = 'light'
      }
      if (item.describe == 'wechat') {
        item.hover = true
      }
    },
    hoverout(item) {
      if (item.status == 'light') {
        item.status = 'normal'
      }
      if (item.describe == 'wechat') {
        item.hover = false
      }
    },
    tolink(linkhref) {
      if (linkhref) {
        window.location.href = linkhref
      }
    },
    tolinkpc(linkhref, describe) {
      if (describe == 'wechat') {
        return
      }
      if (linkhref) {
        window.location.href = linkhref
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '../assets/px.less';
.contentbox {
  // overflow: hidden;
  width: 100%;
  height: @px387;
  background-color: #303133;
}
.content {
  width: @px1200;
  padding: @px40 0 @px80 0;
  margin: 0 auto;
  color: #ffffff;
}
.focus {
  font-weight: bold;
  display: flex;
  align-items: center;
  line-height: @px24;
  font-size: @px16;
  margin-bottom: @px20;
}
.focusicon {
  cursor: pointer;
  width: @px24;
  height: @px24;
}
.focus > div:nth-child(n) {
  margin-right: @px12;
}
.focus > div:nth-child(1) {
  margin-right: @px16;
}
.iconhover {
  position: relative;
  top: -10.688rem;
  left: -3.375rem;
  width: @px132;
  height: @px132;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.line {
  width: 100%;
  height: 0.0315rem;
  background-color: rgba(229, 229, 232, 0.2);
  margin-bottom: @px40;
}
.infor {
  overflow: hidden;
  width: 100%;
  text-align: left;
}
.logoimg {
  float: right;
  width: @px226;
  height: @px80;
}
.bottom {
  margin-top: @px40;
  font-size: @px14;
  line-height: @px20;
}
.Joinus {
  float: left;
  margin-right: @px60;
}
.legal {
  float: left;
  margin-right: @px60;
}
.contact {
  float: left;
}
.Joinus > div:nth-child(n) {
  font-size: @px14;
  line-height: @px22;
}
.legal > div:nth-child(n) {
  font-size: @px14;
  line-height: @px22;
}
.contact > div:nth-child(n) {
  font-size: @px14;
  line-height: @px22;
}
.Joinus > div:nth-child(1) {
  font-size: @px16;
  line-height: @px24;
  font-weight: bold;
  margin-bottom: @px12;
}
.legal > div:nth-child(1) {
  font-size: @px16;
  line-height: @px24;
  font-weight: bold;
  margin-bottom: @px12;
}
.contact > div:nth-child(1) {
  font-size: @px16;
  line-height: @px24;
  font-weight: bold;
  margin-bottom: @px12;
}
.mbcontent {
  padding: @px20 @px20 @px24 @px20;
  width: 100%;
  box-sizing: border-box;
  background-color: #303133;
  color: #ffffff;
  font-size: @px15;
  line-height: @px22;
}
.mbfocus {
  padding-bottom: @px12;
  margin-bottom: @px24;
  border-bottom: 0.0315rem solid rgba(229, 229, 232, 0.2);
  display: flex;
  align-items: center;
}
.mbfocus > div:nth-child(n) {
  margin-right: @px16;
  img {
    width: 100%;
    height: 100%;
  }
}
.mbfocus > div:nth-child(1) {
  margin-right: @px20;
}
.mbfocusicon {
  width: @px22;
  height: @px22;
}
.mbJoinus {
  margin-bottom: @px20;
  .mbJoinus-spread {
    margin-bottom: @px12;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: @px22;
      height: @px22;
    }
  }
  &-show {
    line-height: @px18;
    font-size: @px12;
  }
}
.mblegal {
  margin-bottom: @px20;
  &-spread {
    margin-bottom: @px12;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: @px22;
      height: @px22;
    }
  }
  &-show {
    margin-bottom: @px8;
    line-height: @px18;
    font-size: @px12;
  }
}
.mbcontact {
  margin-bottom: @px20;
  &-spread {
    margin-bottom: @px12;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: @px22;
      height: @px22;
    }
  }
  &-show {
    margin-bottom: @px8;
    line-height: @px18;
    font-size: @px12;
  }
  &-nobuttom {
    margin-bottom: 0;
  }
}
.mbbottom {
  background-color: #fff;
  padding: @px16 @px20 @px40 @px20;
  width: 100%;
  box-sizing: border-box;
  color: #9898a2;
  font-size: @px12;
  line-height: @px18;
  .gotop {
    text-align: center;
    font-weight: bold;
    margin: @px20 auto 0;
    color: #454556;
    line-height: @px24;
    font-size: @px16;
  }
}
.point {
  cursor: pointer;
}
.addressstyle {
  text-indent: 3em;
}
.suPrepared {
  font-size: @px14;
  line-height: @px20;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nationalEmblem {
  float: left;
  margin-top: @px2;
  width: @px14;
  height: @px14;
}
.mbnationalImg {
  width: @px10;
  height: @px10;
}
.bottomStyle{
  display: flex;
  margin-bottom: @px4;
}
</style>
